import React, { useState, useEffect } from "react";
import claudeLogo from "../../assets/claude-logo.png";
import illustrationLogin from "../../assets/artificial-intelligence-illustration.png";
import "./Login.css";
import {
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email required"),
      password: Yup.string().required("Password Required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      Axios.post(`https://s35dph4du3qscd5xh6smywwgge0yhgiq.lambda-url.us-east-1.on.aws/`, values)
        .then((res) => {
          localStorage.setItem("token_admin", JSON.stringify(res.data.token));
          console.log(res.data.token);
          setLoading(false);
          setResponseMsg("");
          
          // Check if temporary_password is true or false and navigate accordingly
          if (res.data.temporary_password) {
            navigate("/reset-password");
          } else {
            navigate("/main");
          }
        })
        .catch((err) => {
          setLoading(false);
          setResponseMsg(err.response?.data?.message || "Incorrect email or password");
        });
    },
  });

  useEffect(() => {
    setIsFormValid(formik.isValid && formik.dirty);
  }, [formik.isValid, formik.dirty]);

  return (
    <div className="main">
      <div className="main-left d-flex flex-column align-items-center justify-content-center">
        <img src={claudeLogo} alt="logo" className="logo-login" />
        <h1 className="main-login">Login</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <TextField
            className="input mb-2"
            label="Email"
            variant="standard"
            type="email"
            name="email"
            InputProps={{
              disableUnderline: true,
            }}
            InputLabelProps={{
              sx: {
                paddingLeft: "20px",
              },
            }}
            sx={{
              fontFamily: "DM Sans",
              fontStyle: "normal",
              fontWeight: "400",
              paddingLeft: "20px",
            }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
          <TextField
            className="input"
            label="Password"
            variant="standard"
            name="password"
            type={showPassword ? "text" : "password"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            sx={{
              fontFamily: "DM Sans",
              fontStyle: "normal",
              fontWeight: "400",
              paddingLeft: "20px",
            }}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: {
                paddingLeft: "20px",
              },
            }}
            error={formik.touched.password && Boolean(formik.errors.password)}
          />
          <p className="error-msg">
            {(formik.errors.email && formik.errors.email) ||
              (formik.errors.password && formik.errors.password) ||
              (responseMsg && responseMsg)}
          </p>
          {/* <Link to="/forgot-password" className="link">
            Can't Login?
          </Link> */}
          {loading ? (
            <CircularProgress style={{ color: "#58c5c8" }} />
          ) : (
            <button
              className={`login-btn ${isFormValid ? "active" : ""}`}
              onClick={formik.handleSubmit}
              type="submit"
              disabled={!isFormValid}
            >
              Login
            </button>
          )}
        </form>
      </div>
      <div className="main-right d-flex flex-row align-items-center justify-content-center">
        <img
          src={illustrationLogin}
          alt="illustration"
          className="illustration-login"
        />
      </div>
    </div>
  );
};

export default Login;
