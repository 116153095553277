import React, { useEffect } from "react";
import Login from "./pages/Login/Login";
import MainPage from './pages/MainPage/MainPage';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import AboutPage from './pages/About/About';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.css";

const App = () => {
  // const dispatch = useDispatch();
  // const email = localStorage.getItem("username");
  // const email = useSelector((state) => state.authAdminLogin);

  // useEffect(() => {
  //   if (localStorage.getItem("token_admin")) {
  //     Axios.post(
  //       `${API_URL}/${localStorage.getItem("username")}/admin/keep-login`,
  //       {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${JSON.parse(
  //             localStorage.getItem("token_admin")
  //           )}`,
  //         },
  //       }
  //     )
  //       .then((res) => {
  //         localStorage.setItem("token_admin", JSON.stringify(res.data.token));
  //         dispatch({
  //           type: "ADMIN_LOGIN_SUCCESS",
  //           payload: res.data.dataLogin,
  //         });
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // }, [dispatch]);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Navigate to="/login" />} />
        <Route element={<Login />} path="/login" />
        <Route element={<ResetPassword />} path="/reset-password" />
        <Route element={<MainPage />} path="/main" />
        <Route element={<AboutPage />} path="/about" />
        {/* <Route element={<Dashboard />} path="/dashboard" /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
