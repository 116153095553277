function About() {
    return (
      <div className="about-container">
        <h1>About Us</h1>
        <p>Phone Number: +62895636593401</p>
      </div>
    );
}

export default About;
