import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './MainPage.css';
import { useNavigate } from "react-router-dom";
import profile from "../../assets/claude-logo.png";

const MainPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token_admin")) {
      navigate("/login");
    }
  }, [navigate]);

  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [TokenBalance, setTokenBalance] = useState(0);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState(['Create a workout plan', 'Activities to make friends in new city', 'Pick outfit to look good on camera', 'Overcome procrastination']);
  const [imageData, setImageData] = useState(null);
  const dropdownRef = useRef(null);
  const chatContainerRef = useRef(null);
  const fileInputRef = useRef(null);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleTaskClick = (task) => {
    setInputValue(task);
  };

  const handleOptionClick = async (option) => {
    if (option === 'Show My Balance') {
      await fetchBalance();
      setModalOpen(true);
    }
    setDropdownOpen(false);

    if ((option === 'Logout')) {
      localStorage.removeItem("token_admin");
      navigate("/login");
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const fetchBalance = async () => {
    try {
      const token = localStorage.getItem("token_admin");
      const response = await axios.get('https://rp7ij4rsgk7brz77acftwvjtsi0dtqie.lambda-url.us-east-1.on.aws/', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      setTokenBalance(response.data.token_balance);
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };

  const handleSubmit = async () => {
    const userMessage = {
      sender: 'You',
      text: inputValue,
      image: imageData ? `data:${imageData.media_type};base64,${imageData.data}` : null
    };
    const loadingMessage = { sender: 'ChatGPT', text: '...' };
  
    setMessages([...messages, userMessage, loadingMessage]);
    setInputValue(''); // Clear input field
    setImageData(null); // Clear image data
    setTasks([]); // Clear tasks immediately
    setLoading(true); // Set loading to true
  
    const payload = {
      messages: messages.map(msg => ({
        role: msg.sender === 'You' ? 'user' : 'assistant',
        content: [
          msg.image
            ? {
                type: 'image',
                source: {
                  type: 'base64',
                  media_type: msg.image.split(';')[0].split(':')[1],
                  data: msg.image.split(',')[1]
                }
              }
            : {
                type: 'text',
                text: msg.text
              }
        ]
      })).concat([
        {
          role: 'user',
          content: imageData
            ? [
                {
                  type: 'image',
                  source: {
                    type: 'base64',
                    media_type: imageData.media_type,
                    data: imageData.data
                  }
                },
                {
                  type: 'text',
                  text: inputValue
                }
              ]
            : [
                {
                  type: 'text',
                  text: inputValue
                }
              ]
        }
      ])
    };
  
    try {
      const token = localStorage.getItem("token_admin");
      const response = await axios.post('https://a7rbyk3geidpk27nzw4pbgmnra0msvuy.lambda-url.us-east-1.on.aws/', payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
    
      let fullText = response.data.messages;
    
      setLoading(false); // Set loading to false
      setMessages(prevMessages => prevMessages.map(msg => msg === loadingMessage ? { ...msg, text: fullText } : msg));
    } catch (error) {
      console.error('Error:', error);
      
      if (error.response && error.response.status === 403) {
        alert("Your token balance is insufficient, please contact seller to top up balance");
      }
    
      setLoading(false); // Set loading to false
      setMessages(prevMessages => prevMessages.map(msg => msg === loadingMessage ? { ...msg, text: 'Error occurred' } : msg));
    }
    
  };
  

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAttachmentClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result.split(',')[1];
        setImageData({
          type: 'base64',
          media_type: file.type,
          data: base64Data
        });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="container">
      {/* {isSidebarOpen && (
        <aside className="sidebar">
          <div className="sidebar-header">
            <div className="tooltip-container-2">
              <button className="close-button" onClick={toggleSidebar}>☰</button>
              <span className="tooltip-text-2">Close sidebar</span>
            </div>
            <div className="tooltip-container">
              <button className="new-chat-button">✏️</button>
              <span className="tooltip-text">New chat</span>
            </div>
          </div>
          <div className="menu-section">Chat History</div>
          {['Create React Frontend Page', 'Create Frontend Page ReactJS', 'Redux Context Setup Error', 'Adjust CSS for illustration', 'Remove PNGTree watermark', 'React-scripts Command Not Found', 'Git PAT Cloning Guide', 'Token Meaning in LLM', 'Cost of 1800 GB Snapshot', 'View LinkedIn profile'].map((item, index) => (
            <div key={index} className="menu-item">{item}</div>
          ))}
        </aside>
      )} */}
      {/* {!isSidebarOpen && (
        <button className="open-sidebar-button" onClick={toggleSidebar}>☰</button>
      )} */}
      <main className={`main-content ${isSidebarOpen ? 'with-sidebar' : 'without-sidebar'}`}>
        {tasks.length > 0 && (
          <div className="task-cards">
            {tasks.map((task, index) => (
              <div key={index} className="task-card" onClick={() => handleTaskClick(task)}>{task}</div>
            ))}
          </div>
        )}
        <div className="chat-container" ref={chatContainerRef}>
          {messages.map((msg, index) => (
            <div key={index} className={`chat-message-container ${msg.sender === 'You' ? 'user-message-container' : 'bot-message-container'}`}>
              {msg.image && (
                <div className="chat-message-image">
                  <img src={msg.image} alt="Uploaded" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                </div>
              )}
              <div className={`chat-message ${msg.sender === 'You' ? 'user-message' : 'bot-message'}`} style={{ whiteSpace: 'pre-wrap' }}>
                {msg.text}
                {loading && msg.text === '...' && <span className="loading-dots"><span>.</span><span>.</span><span>.</span></span>}
              </div>
            </div>
          ))}
        </div>
      </main>
      <div className={`bottom-center`}>
        <div className="input-container">
          <button className="attachment-button" onClick={handleAttachmentClick}>📎</button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          {imageData && (
            <div className="image-preview">
              <img src={`data:image/jpeg;base64,${imageData.data}`} alt="Preview" style={{ maxWidth: '100px', maxHeight: '100px' }} />
            </div>
          )}
          <textarea
            placeholder="Message Claude AI"
            className="text-input centered-placeholder"
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
          />
          <button className="submit-button" onClick={handleSubmit}>⬆️</button>
        </div>
      </div>
      <div className="top-right">
        <div className="profile-button" onClick={toggleDropdown}>
          <div className="profile-icon">🤵🏻</div>
        </div>
        {isDropdownOpen && (
          <div className="dropdown" ref={dropdownRef}>
            <div className="dropdown-item" onClick={() => handleOptionClick('Show My Balance')}>💰 My Balance</div>
            <div className="dropdown-item" onClick={() => handleOptionClick('Logout')}>🔓 Logout</div>
          </div>
        )}
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-modal-button" onClick={closeModal}>&times;</span>
            <p>Token Remaining: {TokenBalance}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainPage;
