import React, { useState, useEffect } from "react";
import claudeLogo from "../../assets/claude-logo.png";
import illustrationLogin from "../../assets/artificial-intelligence-illustration.png";
import "./ResetPassword.css";
import {
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);

  const handleClickShowConfirmNewPassword = () => setShowConfirmNewPassword(!showConfirmNewPassword);
  const handleMouseDownConfirmNewPassword = () => setShowConfirmNewPassword(!showConfirmNewPassword);

  const formik = useFormik({
    initialValues: {
      email: "",
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email required"),
      oldPassword: Yup.string().required("Old Password Required"),
      newPassword: Yup.string().required("New Password Required"),
      confirmNewPassword: Yup.string().required("Confirm New Password Required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      Axios.post(`https://dff62xxp4icw23yw5ua2txrwae0kebrl.lambda-url.us-east-1.on.aws/`, values)
        .then((res) => {
          // localStorage.setItem("token_admin", JSON.stringify(res.data.token));
          setLoading(false);
          setResponseMsg("");
          navigate("/main");
        })
        .catch((err) => {
          setLoading(false);
          setResponseMsg(err.response?.data?.message || "Incorrect email or password");
        });
    },    
  });

  useEffect(() => {
    setIsFormValid(formik.isValid && formik.dirty);
  }, [formik.isValid, formik.dirty]);

  return (
    <div className="main">
      <div className="main-left d-flex flex-column align-items-center justify-content-center">
        <img src={claudeLogo} alt="logo" className="logo-login" />
        <h1 className="main-login">Reset Password</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <TextField
            className="input mb-2"
            label="Email"
            variant="standard"
            type="email"
            name="email"
            InputProps={{
              disableUnderline: true,
            }}
            InputLabelProps={{
              sx: {
                paddingLeft: "20px",
              },
            }}
            sx={{
              fontFamily: "DM Sans",
              fontStyle: "normal",
              fontWeight: "400",
              paddingLeft: "20px",
            }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
          <TextField
            className="input mb-2"
            label="Old Password"
            variant="standard"
            name="oldPassword"
            type={showPassword ? "text" : "password"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.oldPassword}
            sx={{
              fontFamily: "DM Sans",
              fontStyle: "normal",
              fontWeight: "400",
              paddingLeft: "20px",
            }}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: {
                paddingLeft: "20px",
              },
            }}
            error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
          />

          <TextField
            className="input mb-2"
            label="New Password"
            variant="standard"
            name="newPassword"
            type={showNewPassword ? "text" : "password"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
            sx={{
              fontFamily: "DM Sans",
              fontStyle: "normal",
              fontWeight: "400",
              paddingLeft: "20px",
            }}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownNewPassword}
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: {
                paddingLeft: "20px",
              },
            }}
            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
          />

          <TextField
            className="input mb-2"
            label="Confirm New Password"
            variant="standard"
            name="confirmNewPassword"
            type={showConfirmNewPassword ? "text" : "password"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmNewPassword}
            sx={{
              fontFamily: "DM Sans",
              fontStyle: "normal",
              fontWeight: "400",
              paddingLeft: "20px",
            }}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmNewPassword}
                    onMouseDown={handleMouseDownConfirmNewPassword}
                  >
                    {showConfirmNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: {
                paddingLeft: "20px",
              },
            }}
            error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
          />

          <p className="error-msg">
            {(formik.errors.email && formik.errors.email) ||
              (formik.errors.oldPassword && formik.errors.oldPassword) ||
              (formik.errors.newPassword && formik.errors.newPassword) ||
              (formik.errors.confirmNewPassword && formik.errors.confirmNewPassword) ||
              (responseMsg && responseMsg)}
          </p>
          {loading ? (
            <CircularProgress style={{ color: "#58c5c8" }} />
          ) : (
            <button
              className={`login-btn ${isFormValid ? "active" : ""}`}
              onClick={formik.handleSubmit}
              type="submit"
              disabled={!isFormValid}
            >
              Submit
            </button>
          )}
        </form>
      </div>
      <div className="main-right d-flex flex-row align-items-center justify-content-center">
        <img
          src={illustrationLogin}
          alt="illustration"
          className="illustration-login"
        />
      </div>
    </div>
  );
};

export default ResetPassword;
